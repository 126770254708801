.InfluencerOffers {
  width: 100%;
  /* height: calc(100% - 300px); */
  position: relative;
  padding-bottom: 20px;
}

.InfluencerOffers_header h3 {
  color: #170c6b;
  font-weight: 500;
  font-size: 24px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
}

.InfluencerOffers_header {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  flex-direction: column;
  /* margin: 0px 10px; */
}

.InfluencerOffers_header_searchbar {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 10px 0px;
  align-items: center;

  position: sticky;
  top: 0px;
  /* z-index: 1; */
}

.InfluencerOffers_header_searchbar img {
  width: 20px;
  height: 20px;
  margin: 0px 10px;
}

.InfluencerOffers_header_searchbar input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.total_offers_number {
  display: flex;
  gap: 5px;
}

.advanced_filter {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  gap: 5px;
  margin: 0px 10px;
  background-color: #6259ca;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
}

@media (max-width: 700px) {
  .advanced_filter span {
    display: none;
  }
  .InfluencerOffers_header_searchbar input {
    flex: 1;
  }
}

.advanced_filter img {
  margin: 0px;
}

.InfluencerOffers_cards {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-evenly; */
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  padding: 0px 20px;
}

.InfluencerOffers_card {
  /* padding: 20px; */
  background-color: white;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  width: 350px;
  /* flex: 1; */
  height: max-content;
  /* position: relative; */
  cursor: pointer;
}

.mid_section {
  padding: 0px 15px;
}

.offer_banner img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.offerHeading_container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px;
}

.offer_social_tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a76f4;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
}

.offer_social_tag img {
  width: 20px;
  height: 20px;
}

.offerHeading_title {
  font-size: 15px;
  margin: 0px;
  word-wrap: break-word;
}

.offerHeading_comment {
  color: #2a76f4;
  font-size: 17px;
  margin: 0px;
  word-wrap: break-word;
}

.basicIntro {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  margin: 10px 0px;
}

.basicIntro_reach {
  display: flex;
  gap: 20px;
}

.basicIntro_totalReach {
  font-size: 17px;
  color: #2a76f4;
  word-wrap: break-word;
}
.rating_box {
  display: flex;
  align-items: center;
  gap: 5px;

  font-size: 17px;
}
.rating_number {
  color: #e3c700;
}
.total_reviews {
  color: #6c757d;
}

.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  padding-top: 10px;
  border-top: 1px dotted #2a76f4;
}

.card_footer img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card_footer .influencer_name {
  color: #2a76f4;
  font-size: 14px;
}

.card_footer .starting_cost {
  font-size: 15px;
}

.card_footer > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.favourite_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
