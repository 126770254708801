.container_design {
  position: relative;
  border-right: 1px solid #2a76f424;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  width: 100%;
  height: 100px;
  background: white;
}

.background_overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* margin-bottom: -220px; */

  width: 100%;
  height: 100%;
  background: url('../../../assets/brand/Hexagon.svg');
  z-index: 0;
  opacity: 0.06;
}

.titleBox {
  border-bottom: 1px dotted #2a76f485;
  padding: 8px;
  padding-top: 15px;

  margin: 0;
  font-size: 13px;
  font-weight: 700;
  color: #1d212f;

  display: flex;
  justify-content: center;
}

.contentBoxForNumbers {
  padding: 10px;
  display: flex;
  justify-content: center;
}
