.InfluencerProfile_details_scoialCard {
  display: flex;
  margin-top: 4px;
  border: 1px solid #2a76f4;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}
.InfluencerProfile_details_scoialCard div {
  width: 26px;
  height: 26px;
  background: #2a76f4;
  /* padding: 6px; */
  border-radius: 4px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.InfluencerProfile_details_scoialCard div img {
  width: 14px;
  height: 14px;
}

.InfluencerProfile_details_scoialCard span {
  font-size: 14px;
  color: #2a76f4;
  font-weight: 500;
}
