.InfluencerProfile {
  height: 100%;
  padding: 20px;
}

.banner > img {
  /* width: calc(100% - 70px);
   */
  width: 100%;
  height: 100%;
  min-height: 150px;
  max-height: 480px;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  /* margin: 0px 20px; */
}

.profileIcon img {
  position: relative;
  left: 10px;
  bottom: 70px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.41);
  border-radius: 3px;

  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

.profileIcon_right {
  margin-left: 40px;
  position: relative;
  bottom: 100px;
}

.profileIcon_right_top_info {
  color: white;
}

.profileIcon_right_top_info p {
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
}
.box_icon_number {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profileIcon_right_top_info div {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}

.banner_content {
  display: flex;
  background-color: white;
  position: relative;
  /* padding: 10px; */
  height: 100px;
  margin-top: -5px;
  padding-left: 20px;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* width: calc(100% - 70px); */
  max-width: max-content;
  height: 100%;
  gap: 10px;

  /* background-color: #2a76f4; */
  background-color: rgba(42, 118, 244, 0.7);
  color: white;
  border-radius: 8px;
  padding: 10px 10px;
}

.profileIcon_right_top {
  margin-bottom: 30px;
}

.profileIcon_right_top p {
  margin: 0px;
}

.sectionSwitcher {
  display: flex;
  gap: 5px;
}

.sectionSwitcher button {
  background-color: #f2f4f4;
  padding: 10px;
  border: none;
  border-radius: 3px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sectionSwitcher span {
  font-size: 14px;
}

.sectionSwitcher .active {
  background-color: #2a76f4;
  color: white;
}

.sectionSwitcher button:hover {
  background-color: #2a76f4;
}

.sectionSwitcher button:hover span {
  color: white;
}
/* 
.sectionSwitcher button:hover img {
  color: white;
} */

.details {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.details_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 3;
  max-width: 380px;
  min-width: 280px;
  background-color: white;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}

.details_card_profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.details_card_profile > img {
  vertical-align: middle;
  border-style: none;
  max-width: 128px;
  border-radius: 50%;
  margin-top: 20px;
}
.details_card_ReachAndSocial {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.details_card_Reach {
  width: max-content;
  color: #465988;
  font-size: 17px;
  font-weight: 600;
}

.details_card_Social {
  display: flex;
  gap: 5px;
}

.details_card_Social button {
  background-color: #2a76f4;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

.details_card_interest {
  display: flex;
  flex-direction: column;
}

.details_card_interest div {
  flex: 1;
  display: flex;
  gap: 20px;
  padding: 10px;
  padding-left: 20px;
  background-color: #f2f4f4;
  border-top: 1px solid #e8e8f7;
  font-size: 14px;
}

.details_card_paymentHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #2a76f4;
  color: white;
  padding: 10px 20px;
}
.details_card_paymentMethods {
  padding: 10px;
}
.details_card_paymentMethods div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.details_card_paymentMethods div span {
  color: #1d212f;
  font-size: 14px;
}

.details_card_paymentMethods div span:nth-child(1) {
  font-weight: 700;
}
.details_card_paymentMethods div span:nth-child(2) {
  font-weight: 300;
}

.details_right {
  flex: 9;
  background-color: white;
  padding: 20px;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}
.details_right h3 {
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #1d212f;
  font-size: 17px;
}

.details_right p {
  font-size: 15px;
}

.details_scoialInfo {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  background: #2a76f414;
  border-bottom: 2px solid #2a76f4;
}

@media screen and (max-width: 700px) {
  .details {
    flex-direction: column;
  }
  .details_card {
    max-width: 100%;
  }
}

.my_table {
  width: 100%;
  border-collapse: collapse;
}

.my_table th {
  background-color: #f6f8ff;
  font-weight: bold;
  padding: 8px;
  text-align: left;
  border: 1px solid #c5c5c5;
  font-size: 14px;
}

.my_table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #c5c5c5;
  font-size: 13px;
}

@media screen and (max-width: 600px) {
  /* .my_table {
    overflow-x: auto;
    display: block;
  }

  .my_table th,
  .my_table td {
    white-space: nowrap;
  } */
}

.profile_photos_album {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.profile_photos_album div img {
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}

.profile_badges_album {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}
.profile_badges_album div {
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  width: 200px;
  height: 200px;
  background-color: #2a76f4;
  padding: 10px;
}

.profile_badges_album div img {
  width: 100%;
  height: 100%;
}

.profile_work_history {
  width: 100%;
}

.transactions {
  width: 100%;
  /* border-collapse: collapse; */
}

.transactions thead tr th {
  color: #2a76f4;
  text-align: left;
  padding: 10px;
}

td {
  padding: 10px;
}
