.campaigns {
  width: 100%;
}

.campaigns_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}

.campaigns_header h3 {
  margin: 0px;
  margin: 20px;
  color: #170c6b;
  font-weight: 500;
  font-size: 24px;
}

.campaigns_header button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.campaigns_header_btn_duplicate {
  background-color: white;
  color: #424e79;
}

.campaigns_header_btn_create {
  background-color: #6259ca;
  color: white !important;
}

.campaigns_header > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stats_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  gap: 5px;
  flex-wrap: wrap;
  border-bottom: 2px solid #2a76f4;
}

.stats_row > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* min-width: 200px; */
  flex: 1;
  height: 120px;
  background-color: white;
  gap: 10px;
}

.stats_box_title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin: 0px;
}

.stats_box_value {
  font-size: 30px;
  font-weight: bold;
  color: #2a76f4;
}

.campaigns_table_container {
  /* width: 100%; */
  overflow-x: auto;
  margin-top: 10px;
  padding: 10px;
  /* background-color: white; */
}

.campaigns_table_container_card {
  background-color: white;
  padding: 30px;
}

@media (max-width: 700px) {
  .campaigns_header {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .stats_row > div {
    min-width: 100px;
  }
  .campaigns_table_container {
    max-width: calc(100vw - 30px);
  }
  .campaigns_table_container_card {
    width: fit-content;
  }
}

.campaigns_table {
  width: 100%;
  border-collapse: collapse;
}

/* header background color is light blue */
.campaignTableHeader {
  background-color: #efefff;
}

.campaigns_table th {
  padding: 8px 10px;
  font-weight: 700;
  font-size: 12px;
  color: #434b63;
}

.campaigns_table tbody td {
  font-size: 13px;
}

.table_image_brand {
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
}

.brand_name__logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table_image_brand {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.table_image_brand_big {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.campaigns_table td,
.campaigns_table th:not(.campaignFilterSearch) {
  border: 1px solid #e8e8f7; /* add border to cells */
  padding: 8px; /* add some padding to cells for spacing */
}

/* search inputs should be fit in cell not go outside */
.campaigns_table input,
select,
.campaigns_table_container_card_filter input {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  height: 37px;
  padding: 0 10px;
}

.campaigns_table_container_card_header {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  margin-bottom: 10px;
}

.campaigns_table_container_card_header > div {
  display: flex;
  flex-direction: column;
}

.campaigns_table_container_card_footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.recordShowingStats {
  color: #1d212f;
  font-size: 14px;
  margin-top: 15px;
}

.campaigns_table_container_card_footer_pagination {
  display: flex;
}

.campaigns_table_container_card_footer_pagination_prev,
.campaigns_table_container_card_footer_pagination_next {
  color: #a8afc7;
  cursor: pointer;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #e8e8f7;
  font-weight: 400;
}

.campaigns_table_container_card_footer_pagination_prev {
  border-radius: 6px 0 0 6px;
}

.campaigns_table_container_card_footer_pagination_next {
  border-radius: 0px 6px 6px 0px;
}

.campaigns_table_container_card_footer_page_no {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #e8e8f7;
  font-weight: 400;
  background-color: #2a76f4;
  color: white;
}

/* color mapping */

.status_running {
  color: #49a240;
}
.status_completed {
  color: #49a240;
}
.status_draft {
  color: #f16d75;
}
.status_pitches {
  color: black;
}

.campaigns_table_btn_edit {
  border: none;
  outline: none;
  border-radius: 3px;
  background-color: #19b159;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
