.messagges_main {
  width: 100%;
  height: 100%;
}
.messages_container_title h3 {
  margin: 0px;
  margin: 20px;
  color: #170c6b;
  font-weight: 500;
  font-size: 24px;
}

.chatbox {
  width: 100%;
  display: flex;
}

.chatbox > div {
  flex: 1;
}

.users_list {
  max-width: 300px;
  /* height: 100%; */
  height: calc(100vh - 160px);
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  padding: 10px 10px;
  /* background-color: #899ee2; */
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.users_list::-webkit-scrollbar {
  width: 5px;
}

.users_list::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

.users_list::-webkit-scrollbar-thumb {
  background-color: #170c6b;
  border-radius: 10px;
}

.users_list::-webkit-scrollbar-thumb:hover {
  background-color: #170c6b;
}

.user {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.user .user_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user_details_container {
  margin-left: 10px;
}

.user_details_container > p {
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
}

.user_details_container .user_name {
  font-weight: 500;
  color: #170c6b;
}

.user_details_container .user_type {
  font-size: 12px;
  color: #7b7b7b;
}

.unseen_count {
  background-color: #170c6b;
  color: white;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  position: absolute;
  right: 10px;
  top: 5px;
}
