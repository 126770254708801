.referralMain {
  width: 100%;
  height: 100%;
}

.topCardsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tabularContentContainer {
  padding: 0px 20px;
  overflow-x: auto;
}

@media (max-width: 1100px) {
  .topCardsContainer {
    flex-direction: column;
    align-items: center;
  }
}

.table_container {
  padding-top: 120px !important;
}

.tabularContentContainer_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
  top: 120px;
  margin-top: -100px;
}

@media screen and (max-width: 900px) {
  .code__button {
    top: 0px;
    margin-top: 0px;
    flex-direction: column-reverse;
  }
  .tabularContentContainer_header {
    top: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .table_container {
    padding-top: 30px !important;
  }
}

.tabularContentContainer_header .code__button {
  display: flex;
  width: 100%;
  align-items: center;
}

.tabularContentContainer_header .code__button div {
  flex: 1;
  display: flex;
  justify-content: center;
}

.modal_button_container {
  justify-content: flex-end !important;
}

.code__title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin: 0px;
}

.modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #2a76f4;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.code__number {
  color: #2a76f4;
  font-weight: bold;
  font-size: 21px;
  background: #e6e9f5;
  width: 200px;
  border-radius: 4px;
  padding: 13px;
  box-shadow: -7.829px 11.607px 20px 0px #e4e3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.code__title {
  font-size: 27px;
  color: #1d212f;
  font-weight: 600;
}

.modal_button {
  color: #fff;
  background-color: #6259ca;
  max-height: 38px;
  width: 190px;
  max-width: 190px;
  font-weight: 400;
}

/* color mapping */

.status_process {
  color: #49a240;
}
.under_process {
  color: #f16d75;
}

/* modal css */

.modalMain {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal_container {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 5px;
  position: relative;
}

.modal_container > div {
  padding: 20px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a76f4;
  color: white;
}
.modal_header_close_button {
  cursor: pointer;
}
.modal_body p {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.modal_body input {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e6f1;
  margin-bottom: 10px;
}

.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e1e6f1;
}

.modal_footer .btn_groups {
  display: flex;
  gap: 10px;
}

.modal_footer .btn_groups button {
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn_cancel {
  background-color: #fd6074;
}

.btn_request {
  background-color: #2a76f4;
}

.modal_body .error_message {
  text-align: center;
  color: #fd6074;
  font-size: 14px;
  margin-top: -5px;
}
