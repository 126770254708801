.InfluencerProfile {
  height: 100%;
  padding: 20px;
}

.banner {
  position: relative;
}

.banner > img {
  width: 100%;
  height: 100%;
  min-height: 150px;
  max-height: 480px;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  /* margin: 0px 20px; */
}

.bannerChange_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
}

.bannerChange_overlay:hover {
  opacity: 1;
}

.overlay_button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.delete_photo_button {
  background-color: #df8484;
}

.delete_photo_button:hover {
  background-color: #ff0000;
}

.upload_photo_button {
  background-color: #4a90e2;
  position: relative;
}

.upload_photo_button:hover {
  background-color: #0070f3;
}

.upload_photo_button input {
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
  height: 40px;
  /* background-color: white; */
  opacity: 0;
}

.album_photo_container {
  position: relative;
  height: max-content;
}

.album_photo_container_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
}

.album_photo_container_overlay:hover {
  opacity: 1;
}

.details {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.details_right {
  flex: 9;
  background-color: white;
  padding: 20px;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}

@media screen and (max-width: 700px) {
  .details {
    flex-direction: column;
  }
}

.profile_photos_album {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.profile_photos_album_single {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.profile_photos_album div img {
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
  max-width: 300px;
  max-height: 300px;
}
