.referralMain {
  width: 100%;
  height: 100%;
}

.topCardsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tabularContentContainer {
  padding: 0px 20px;
  overflow-x: auto;
}

@media (max-width: 1100px) {
  .topCardsContainer {
    flex-direction: column;
    align-items: center;
  }
}

.table_container {
  padding-top: 90px !important;
}

.tabularContentContainer_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
  top: 120px;
  margin-top: -100px;
}

@media screen and (max-width: 900px) {
  .code__button {
    top: 0px;
    margin-top: 0px;
    flex-direction: column-reverse;
  }
  .tabularContentContainer_header {
    top: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .table_container {
    padding-top: 30px !important;
  }
}

@media (max-width: 700px) {
  .tabularContentContainer {
    padding: 0px 10px;
    width: calc(100vw - 20px);
  }
  .table_container {
    padding: 20px;
    overflow-x: auto;
  }
}

.tabularContentContainer_header .code__button {
  display: flex;
  width: 100%;
  align-items: center;
}

.tabularContentContainer_header .code__button div {
  flex: 1;
  display: flex;
  justify-content: center;
}

.modal_button_container {
  justify-content: flex-end !important;
}

.code__title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin: 0px;
}

.modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #2a76f4;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.code__number {
  color: #2a76f4;
  font-weight: bold;
  font-size: 21px;
  background: #e6e9f5;
  width: 200px;
  border-radius: 4px;
  padding: 13px;
  box-shadow: -7.829px 11.607px 20px 0px #e4e3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.code__title {
  font-size: 27px;
  color: #1d212f;
  font-weight: 600;
}

.modal_button {
  color: #fff;
  background-color: #6259ca;
  max-height: 38px;
  width: 190px;
  max-width: 190px;
  font-weight: 400;
}

/* color mapping */

.status_converted {
  color: #49a240;
}
.status_non_converted {
  color: #f16d75;
}
