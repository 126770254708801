.myProfile {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 21px;
  font-weight: 500;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  /* margin-left: 20px; */
  color: #1d212f;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px;
  margin-top: 20px; */
  padding: 20px 20px;
  background-color: #fff;
}

.header_right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header_button {
  background-color: #2a76f4;
  color: #fff;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
}

.header_button:hover {
  background-color: #8f9cc0;
}

@media (max-width: 1000px) {
  .header {
    flex-direction: column;
  }
  .header_right {
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
  }
}
