.columns {
  margin: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

@media screen and (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
}

.columns .column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}

.section {
}

.sectionHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 400px; */
  background-color: #2a76f4 !important;
  color: #fff;
  padding: 10px 15px;
}
.sectionHeading button {
  background-color: white;
  color: #2a76f4;
  padding: 8px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
/* .sectionTableContainer {
  display: flex;
} */

.sectionTableContainer {
  width: 100%;
}

.sectionTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #0000a0;
}
.sectionTable td {
  border: 1px solid #ccc;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Mulish', sans-serif;
}

.sectionTable tr:nth-child(even) {
  background-color: #ffffff;
}

.sectionTable tr:nth-child(odd) {
  background-color: #f6f8ff;
}

.sectionTable_borderLess {
  border: none;
  height: 50px;
}
.sectionTable_borderLess thead {
  height: 40px;
}
.max_width {
  width: max-content;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteButton {
  background-color: #fd6074;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}
.deleteButton:hover {
  background-color: #e91e63;
}

.ProfileIcon img {
  width: 100px;
  height: 100px;
  border-radius: 10%;
  object-fit: cover;
  margin-right: 10px;
}
