.campaignSummaryTxn {
  width: 100%;
}
.campaignSummaryTxn_header {
  /* padding: 20px; */
}
.campaignSummaryTxn_header h3 {
  margin: 0px;
  margin: 20px;
  color: #170c6b;
  font-weight: 500;
  font-size: 24px;
}

.summary_chart {
  width: calc(100% - 5px);
}
.summary_chart_canvas {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 0px 20px;
  height: 300px;
}
.tabularContentContainer {
  padding: 20px;
}
.no_data {
  font-size: 18px;
  border-radius: 10px;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}
.no_data span {
  color: #170c6b;
  font-weight: 500;
  cursor: pointer;
}

/* color mapping */

.status_process {
  color: #28a745;
}
.under_process {
  color: #dc3545;
}
