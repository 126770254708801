.dashboardMain {
  width: 100%;
  height: 100%;
}

.container_design {
  position: relative;
  border-right: 1px solid #2a76f424;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  width: 100%;
  height: 220px;
  background: white;
}

.background_overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* margin-bottom: -220px; */

  width: 100%;
  height: 100%;
  background: url('../../../assets/brand/Hexagon.svg');
  z-index: 0;
  opacity: 0.06;
}

.dashboardMain_firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.titleBox {
  border-bottom: 1px dotted #2a76f485;
  padding: 8px;
  padding-left: 15px;
  padding-top: 15px;
}
.titleBox p {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: #5b6069;
}

.contentBox {
  display: flex;
  width: 100%;
  padding: 24px 10px;
  position: relative;
}
.contentBox img {
  width: 110px;
  height: 110px;
  padding: 0px 20px;
}
.contentBox div {
  margin-right: 20px;
}

.contentBox div p {
  margin: 0px;
  margin-bottom: 10px;
}

.contentBox div div {
  color: #007bff;
  font-weight: 500;
  z-index: 100;
  cursor: pointer;
}

.contentBoxForNumbers {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.cardWithNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
}

.cardWithNumber p {
  margin: 0px;
}
.cardWithNumber span {
  margin-top: 10px;
  color: green;
}

@media (max-width: 1100px) {
  .dashboardMain_firstRow {
    flex-direction: column;
    align-items: center;
  }
  .contentBox {
    display: flex;
    width: 100%;
    padding: 24px 0px;
  }
}

.dashboardMain_secondRow {
  padding: 20px;
  display: flex;
  gap: 10px;
}
.dashboardMain_secondRow_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.dashboardMain_secondRow_left_bottom {
  display: flex;
  gap: 10px;
}

.dashboardMain_secondRow_left_top {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  width: -webkit-fill-available;
}

.dashboardMain_secondRow_left_top div {
  color: #007bff;
  cursor: pointer;
}

.oneInfleuncerCard,
.oneReferralCard {
  background-color: white;
}

.oneInfleuncerCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 1;
  cursor: pointer;
}

.oneInfleuncerCard img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.oneInfleuncerCard h3 {
  margin: 0px;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 600;
}

.oneInfleuncerCard p {
  margin: 0px;
  text-align: center;
  font-size: 15px;
  color: #474747;
}

.oneInfleuncerCard span {
  color: #007bff;
  font-size: 14px;
  font-weight: 500;
  margin: 8px 0px;
}

.oneReferralCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oneReferralCard img {
  width: 100px;
  height: 100px;
}

.oneReferralCard h3 {
  margin: 0px;
  text-align: center;
}

.oneReferralCard p {
  margin: 10px;
  text-align: center;
}
.referral_button {
  background-color: #2a76f4;
  color: white;
  width: 90%;
  height: 40px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.dashboardMain_secondRow_right,
.oneReferralCard {
  max-width: 350px;
}

@media screen and (max-width: 1100px) {
  .dashboardMain_secondRow {
    flex-direction: column;
    align-items: center;
  }
  .oneReferralCard {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .dashboardMain_secondRow_left {
    flex-direction: column;
    align-items: center;
  }
  .dashboardMain_secondRow_right,
  .oneReferralCard {
    max-width: 100%;
  }
}

.profileImageScaleNormal {
  animation: scaleUp 0.5s ease-in forwards;
}

.profileImageScaleDown {
  animation: scaleDown 0.5s ease-in-out forwards;
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}
