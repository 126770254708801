.main {
  height: 100%;
  padding: 20px;
}

.sectionSwitcher {
  display: flex;
  gap: 5px;
}

.sectionSwitcher button {
  background-color: #f2f4f4;
  padding: 10px;
  border: none;
  border-radius: 3px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sectionSwitcher span {
  font-size: 14px;
}

.sectionSwitcher .active {
  background-color: #2a76f4;
  color: white;
}

.sectionSwitcher button:hover {
  background-color: #2a76f4;
}

.sectionSwitcher button:hover span {
  color: white;
}
.details {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.details_left {
  max-width: 480px;
  min-width: 280px;
}

.details_card {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
  height: max-content;
}

.details_card_profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.details_card_profile > img {
  vertical-align: middle;
  border-style: none;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  margin-top: 20px;
}
.details_card_ReachAndSocial {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.title {
  width: max-content;
  color: #465988;
  font-size: 17px;
  font-weight: 600;
}

.details_card_makeOffer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #2a76f4;
  color: white;
  padding: 10px 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
}

.details_right {
  flex: 9;
  background-color: white;
  padding: 20px;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}
.details_right h3 {
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #1d212f;
  font-size: 17px;
}

.details_right h2 {
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #1d212f;
  font-size: 14px;
}

.details_right p {
  font-size: 15px;
}
@media screen and (max-width: 700px) {
  .details {
    flex-direction: column;
  }
  .details_card {
    max-width: 100%;
  }
}

.left_top {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Open Sans', sans-serif;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  /* padding: 0px 20px; */
}

.left_top > div {
  background-color: #f2f4f4;
  width: calc(100% - 40px);
  padding: 10px;
  border-radius: 5px;
  margin: 0px 10px;
}

.left_top > p {
  margin: 0px;
  padding: 10px;
  border-radius: 5px;
  margin: 0px 10px;
  color: #2a76f4;
}

.tab_form * {
  margin: 0px;
}

.single_group {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  gap: 10px;
}

.textarea_design {
  /* width: 100%; */
  background-color: #f6f6ff;
  min-height: 80px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Open Sans', sans-serif;
  resize: none;
}
.internal_heading {
  background: #f2f4f4;
  color: black;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 16px;
}

.objectives {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.objective_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.objective_container img {
  width: 50px;
  height: 50px;
}

.single_row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: space-between;
}
.label_input_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  /* flex: 1;
  max-width: 380px;
  min-width: 280px;*/
}
.label_input_container div {
  background-color: #f6f6ff;
  padding: 5px 10px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Mulish', sans-serif;
  color: #000000;
  border: 1px solid #d2d2d2;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 4px;
  min-height: 21px;
}

.extra_top_margin {
  margin-top: 20px;
}

.boxes_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.boxes {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.one_box {
  display: flex;
  align-items: center;
  background: #2a76f4e6;
  border-radius: 3px;
  color: #fff;
  padding: 4px 10px;
  margin-bottom: 10px;
  font-size: 12px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
}

.single_row_radio {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin: 25px 0px;
}

.social_platform_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin: 25px 0px;
}

.social_platform_container img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #2a76f4;
  padding: 10px;
}

.document_list {
  margin-bottom: 10px;
  width: 100%;
}

.document_list_table {
  width: 100%;
  border-collapse: collapse;
  border: #000000 1px solid;
}
.document_list_table tr {
  /* border-bottom: 1px solid #e0e0e0; */
  border-collapse: collapse;
}
.document_list_table td,
.document_list_table th {
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 400 !important;
  font-family: 'Open Sans', sans-serif;
  border: #000000 1px solid;
  text-align: center;
}

.download_button {
  background-color: #2a76f4;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Mulish', sans-serif;
  border: none;
  cursor: pointer;
}
