.listOfInfluencers {
  width: 100%;
  height: calc(100% - 300px);
  position: relative;
}

.listOfInfluencers_header h3 {
  color: #170c6b;
  font-weight: 500;
  font-size: 24px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
}

.listOfInfluencers_header {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  flex-direction: column;
  /* margin: 0px 10px; */
}

.listOfInfluencers_header_searchbar {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 10px 0px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;
  top: 0px;
  /* z-index: 1; */
}

.listOfInfluencers_header_searchbar img {
  width: 20px;
  height: 20px;
  margin: 0px 10px;
}

.listOfInfluencers_header_searchbar input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.total_influencers_number {
  display: flex;
  gap: 10px;
}

.listOfInfluencers_header_searchbar .sorting {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.listOfInfluencers_header_searchbar .sorting select {
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: white;
  border: 1px solid black;
}

.listOfInfluencers_header_searchbar > div {
  display: flex;
  align-items: center;
}

.listOfInfluencers_card {
  /* padding: 20px; */
  background-color: white;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  width: 320px;
  /* flex: 1; */
  height: max-content;
  /* position: relative; */
}

.listOfInfluencers_cards {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-evenly; */
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  padding: 0px 20px;
}
.basicIntro {
  padding: 20px;
  padding-bottom: 10px;

  display: flex;
  gap: 20px;
  align-items: center;

  cursor: pointer;
}

.basicIntro img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.basicIntro h5 {
  margin: 0px;
  margin-bottom: 8px;
}

.basicIntro_name {
  font-weight: 500;
  font-size: 17px;
  color: #2a76f4;
  word-wrap: break-word;
}

.basicIntro_totalReach {
  font-weight: 500;
  font-size: 15px;
  color: #e57b1b;
  margin-bottom: 5px;
}

.socialTags {
  display: flex;
  padding: 5px 5px;
  gap: 10px;
  background: #2a76f414;
}

.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-top: 10px;
  /* border-top: 1px dotted #e5e5e5; */
  border-top: 1px dotted #2a76f4;
  /* position: relative; */
  /* bottom: 0px; */
}

.card_footer > div {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
}
.vertical_separator_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical_separator {
  height: 15px;
  width: 1px;
  background-color: black;
}

.interests {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  padding: 0px 10px;
}

.interests div {
  background: #77d2af;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  padding: 7px 8px;
  margin-bottom: 0;
  cursor: pointer;
}

.profileImageScaleNormal {
  animation: scaleUp 0.5s ease-in forwards;
}

.profileImageScaleDown {
  animation: scaleDown 0.5s ease-in-out forwards;
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}

@media (max-width: 700px) {
  .listOfInfluencers_header_searchbar > div {
    width: 100%;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .listOfInfluencers_cards {
    justify-content: center;
  }
}
