.clientMain {
  width: 100%;
  height: 100%;
}

.tabularContentContainer {
  padding: 0px 20px;
  overflow-x: auto;
}

.table_container {
  margin-top: 20px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

@media screen and (max-width: 900px) {
  .table_container {
    padding-top: 30px !important;
  }
}

.tableHeading {
  background: #e7e9f4;
  padding: 10px;
  border-bottom: 2px solid #2a76f4;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tableHeadingTitle {
  margin: 0px;
  font-size: 23px;
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
}

.table {
  width: 100%;
}

.tableRow {
  display: flex;
  gap: 20px;
  align-items: end;
}

.tableCell {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.tableCellData {
  width: 100%;
  margin: 0;
  min-height: 15px;
  padding: 9px 10px;
  background: #f6f6ff;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  color: #000;

  display: flex;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .tableRow {
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }
  .tableCell {
    width: calc(100% - 40px);
  }
}
