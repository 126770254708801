.admin_dashboard {
  display: flex;
  height: 100%;
}
.admin_dashboard_container {
  flex: 1;
  height: 100%;
}

.admin_dashboard_content {
  height: calc(100vh - 66px);
  /* width: 100%; */
  background-color: #eaedf7;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.admin_dashboard_content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.admin_dashboard_content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 1100px) {
  .admin_dashboard_content {
    margin-left: 80px;
  }
}

@media (max-width: 700px) {
  .admin_dashboard_content {
    margin-left: 0px;
  }
}
