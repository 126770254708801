.campaignSummary {
  width: 100%;
}
.campaignSummary_header {
  /* padding: 20px; */
}
.campaignSummary_header h3 {
  margin: 0px;
  margin: 20px;
  color: #170c6b;
  font-weight: 500;
  font-size: 24px;
}

.summary_chart {
  width: calc(100% - 5px);
}
.summary_chart_canvas {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 0px 20px;
  height: 300px;
}
.tabularContentContainer {
  padding: 20px;
}
