.table_container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.common_table {
  width: 100%;
  border-collapse: collapse;
}

.common_tableHeader {
  background-color: #efefff;
}

.common_table th {
  padding: 8px 10px;
  font-weight: 700;
  font-size: 12px;
  color: #434b63;
}

.common_table tbody td {
  font-size: 13px;
}

.table_image_brand {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.brand_name__logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.common_table td,
.common_table th:not(.filterSearch) {
  border: 1px solid #e8e8f7;
  padding: 8px;
}

.common_table input,
select,
.table_container_filter input {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  height: 37px;
  padding: 0 10px;
}

.table_container_header {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  margin-bottom: 10px;
}

.table_container_header > div {
  display: flex;
  flex-direction: column;
}

.table_container_footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.table_container_footer_pagination {
  display: flex;
}

.table_container_footer_pagination_prev,
.table_container_footer_pagination_next {
  color: #a8afc7;
  cursor: pointer;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #e8e8f7;
  font-weight: 400;
}

.table_container_footer_pagination_prev {
  border-radius: 6px 0 0 6px;
}

.table_container_footer_pagination_next {
  border-radius: 0px 6px 6px 0px;
}

.table_container_footer_page_no {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #e8e8f7;
  font-weight: 400;
  background-color: #2a76f4;
  color: white;
}

.no_of_records {
  color: #1d212f;
  font-size: 14px;
  margin-top: 15px;
}
