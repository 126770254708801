.InfluencerProfile {
  height: 100%;
  padding: 20px;
}

.sectionSwitcher {
  display: flex;
  gap: 5px;
}

.sectionSwitcher button {
  background-color: #f2f4f4;
  padding: 10px;
  border: none;
  border-radius: 3px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sectionSwitcher span {
  font-size: 14px;
}

.sectionSwitcher .active {
  background-color: #2a76f4;
  color: white;
}

.sectionSwitcher button:hover {
  background-color: #2a76f4;
}

.sectionSwitcher button:hover span {
  color: white;
}
.details {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.details_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 3;
  max-width: 380px;
  min-width: 280px;
  background-color: white;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
  height: max-content;
}

.details_card_profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.details_card_profile > img {
  vertical-align: middle;
  border-style: none;
  max-width: 128px;
  border-radius: 50%;
  margin-top: 20px;
}
.details_card_ReachAndSocial {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.details_card_Reach {
  width: max-content;
  color: #465988;
  font-size: 17px;
  font-weight: 600;
}

.details_card_Social {
  display: flex;
  gap: 5px;
}

.details_card_Social button {
  background-color: #2a76f4;
  border: none;
  border-radius: 5px;
  padding: 5px;
}
.offer_price {
  display: flex;
  gap: 20px;
  padding: 10px;
  padding-left: 20px;
  background-color: #2a76f4;
  border-top: 1px solid #e8e8f7;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.offer_price img {
  width: 20px;
  height: 20px;
}

.details_card_makeOffer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #2a76f4;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.details_right {
  flex: 9;
  background-color: white;
  padding: 20px;
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}
.details_right h3 {
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #1d212f;
  font-size: 17px;
}

.details_right h2 {
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #1d212f;
  font-size: 14px;
}

.details_right p {
  font-size: 15px;
}

.details_scoialInfo {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  background: #2a76f414;
  border-bottom: 2px solid #2a76f4;
}

@media screen and (max-width: 700px) {
  .details {
    flex-direction: column;
  }
  .details_card {
    max-width: 100%;
  }
}

.profile_photos_album {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.profile_photos_album div img {
  box-shadow: -8px 12px 18px 0 rgb(25 42 70 / 13%);
  border-radius: 4px;
}

.profile_details {
  width: 100%;
}

.profile_details ul {
  list-style: none;
  padding: 0px;
}

.profile_details ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.profile_details ul li span {
  color: #2a76f4;
  margin-left: 5px;
}
.profile_details ul li a {
  color: #2a76f4;
  margin-left: 5px;
  text-decoration: none;
}
.profile_details ul img {
  margin-right: 10px;
}

.interest_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.interest_container .interest {
  background-color: #2a76f4;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 12px;
}
