.login {
  background-image: url('../../../assets//common/login_background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 40px);
  padding: 20px;
}

.section_name {
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.section_name span {
  padding: 15px;
}

.section_name span:hover {
  cursor: pointer;
}

.healine_hr {
  margin: 0 30px;
  border: 0.5px solid #ebebeb;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 40%;
  height: max-content;
  background: #fff;
  box-shadow: 0px 17px 43px 1px rgba(26, 94, 221, 0.4);
}

.influencer_active {
  font-weight: 600;
  border-bottom: 2.5px solid #2a75f4;
}

.brand_active {
  font-weight: 600;
  border-bottom: 2.5px solid #2a75f4;
}

.login_form_section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.or_login_with_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
}

.or_login_with_section hr {
  width: 20%;
  border: 0.5px solid #ebebeb;
}

.or_login_with {
  display: inline-block;
  font-size: 20px;
  padding: 20px;
}

.form_options_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px;
}

.checkbox_div {
  display: flex;
  align-items: center;
  gap: 3px;
}

.checkbox_label {
  color: #8a8eaa;
  font-size: 15px;
}

.checkbox_input {
  width: 17px;
  height: 17px;
}

.login_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a76f4;
  border-radius: 30px;
  color: #ffffff;
  width: 80%;
  padding: 10px;
  font-size: 17px;
  border: none;
  box-shadow: 0px 17px 43px 1px rgba(26, 94, 221, 0.4);
}

.login_button:hover {
  background: #42474a;
}

.bottom_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot_password {
  color: #8a8eaa;
  font-size: 15px;
  margin: 0;
}

.no_account {
  color: #8a8eaa;
  font-size: 17px;
  display: flex;
  justify-content: center;
}

.register_link {
  color: #8a8eaa;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.input_fields_div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.influencer_field {
  min-height: 50px;
  border: 1px solid #e7e7e7;
  padding-left: 20px;
  outline: #e7e7e7;
  border-radius: 6px;
  margin: 10px;
  min-width: 80%;
}

@media screen and (max-width: 700px) {
  .section_name {
    margin-bottom: 15px;
  }

  .section_name span {
    padding: 5px;
    font-size: 20px;
  }

  .card {
    width: 80%;
  }

  .login_form_section {
    padding: 3px;
  }

  .or_login_with_section hr {
    width: 50px;
  }

  .or_login_with {
    padding: 10px;
    font-size: 15px;
  }

  .form_options_div * {
    font-size: 12px;
  }
}

.formErrorMsg {
  color: red;
  font-size: 14px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  text-align: center;
}
