.social_media_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.social_media_buttons div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #ffffff;
    font-size: 17px;
    border: none;
    min-width: 100px;
    height: 50px;
    padding: 0px 10px;
}

.social_media_buttons div:hover {
    background: #333536;
    box-shadow: 0px 17px 43px 1px rgba(26, 94, 221, 0.4);
}

.button_image {
    height: 30px;
    width: 30px;
}

.google_button {
    background: #e83f3f;
}

.facebook_button {
    background: #1778f2;
}


.twitter_button {
    background: #1da1f2;
}