.main {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  height: calc(100% - 100px);
  background-color: #ffffff;
  border: 1px dashed #c1c1c1;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: calc(100vh - 140px);
  max-width: calc(100% - 385px);
}

.messages_body {
  flex-grow: 1;
  overflow: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 30px;
  gap: 30px;
  padding-top: 10px;
  background-color: #ffffff;
}

.footer {
  /* padding-left: 7px; */
  padding-right: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
}

.chat_msg_input {
  background: #f3f3f3;
  border: 1px dashed #c1c1c1;
  border-radius: 10px;
  flex: 1;
  height: 50px;
  /* margin-left: 15px; */
  margin-right: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #7b7b7b;
  padding: 18px;
  padding-left: 20px;
  padding-right: 20px;

  overflow-y: hidden;
  resize: none;
}

.chat_msg_send_logo {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .main {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px;
    /* border: none; */
    padding: 10px;
    background-color: #f6f6f6;

    height: calc(100vh - 230px) !important;
    margin-bottom: -50px !important;
  }

  .messages_body {
    padding: 0px;
  }

  .footer {
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .Requests_accept_reject_buttons {
    gap: 15px;
  }

  /* .Requests_Main_below{
        padding-bottom: 0px !important;
    } */
}
