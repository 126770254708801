.brand_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  flex: 1;
  padding-left: 25px;
  padding-right: 25px;
  height: 65px;

  z-index: 100 !important;
  border-bottom: 1px solid #e1e6f1;
  box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
  position: sticky;
}

.brand_header_left {
  display: flex;
  align-items: center;
}

.brand_header_menuIcon,
.brand_header_menuIcon_dot {
  height: 30px;
  width: 20px;
}

.brand_header_menuIcon {
  margin-right: 20px;
}

.brand_header_faq a {
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.brand_header_right {
  display: flex;
  align-items: center;
}

.brand_header_right_middle {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.brand_header_right_middle img {
  height: 20px;
  margin-right: 10px;
}

.brand_header_right_middle div {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
}

.brand_header_right_middle div div {
  margin-left: 5px;
}

.brand_header_right img {
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.brand_header_right div {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
}

.brand_header_right div div {
  margin-left: 5px;
}

.countNumberPosition {
  position: relative;
  top: -10px;
  left: -15px;
  margin-left: -20px;
  background: rgb(59, 214, 21);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
}

.IconSizeSmall {
  width: 18px;
  height: 18px;
}

.IconSizeBig {
  width: 35px;
  height: 35px !important;
  border-radius: 50%;
}

@media (max-width: 700px) {
  .brand_header_right {
    border-top: #e1e6f1 1px solid;
    border-bottom: #e1e6f1 1px solid;
    padding: 10px 0px;
    position: absolute;
    background-color: white;
    top: 65px;
    width: 100%;
    margin-left: -25px;
    display: flex;
    justify-content: center;
    /* display: none; */
  }
}

@media (min-width: 700px) {
  .brand_header_menuIcon_dot {
    display: none;
  }
}

.profilePopUp {
  margin: 0px !important;
  position: absolute;
  right: 20px;
  top: 65px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 200px;
  border: 0;
  border-width: 1px;
  box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
}

.profilePopUp_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
}

.profilePopUp_info > div:nth-child(2) {
  font-size: 13px;
  font-weight: 400 !important;
}

.profilePopUp_cta_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
  margin-top: 10px;
  width: 100%;
}
.profilePopUp_cta_list hr {
  border: none;
  outline: none;
  height: 0.1px;
  margin: 0px;
  width: 98%;
  position: relative;
  left: -2px;
  /* background-color: #dce2e9; */
  border-top: 1px solid #e8e8f7;
}

.profilePopUp_cta_container {
  width: 100%;
  display: flex;
  /* gap: 10px; */
  padding: 10px 0px;
  margin: 0px !important;
  padding-left: 0px !important;
  cursor: pointer;

  position: relative !important;
  left: -3px !important;
  /* padding-left: 10px !important; */
}

.profilePopUp_cta_container > div {
  font-size: 13px;
  font-weight: 400 !important;
}

.profilePopUp_cta_container img {
  /* height: 20px; */
  margin-left: 10px;
}

.profilePopUp_cta_list > div:hover {
  background-color: #e8e8f7;
}
