.sidebar {
  position: relative;
  width: 80px;
  height: 100vh;
  background-color: #2a76f4;
  animation-name: sidebarActiveAnimation2;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

.sidebar .sidebarOneItem_white {
  display: none;
}

.sidebar .sidebarOneItem_active_name {
  display: none;
}

.sidebarActive {
  /* position: absolute;
  top: 65px; */
  left: 0;
  background-color: #2a76f4;
  height: 100vh;
  width: 240px;
  animation-name: sidebarActiveAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

.sidebar_logo_container {
  padding: 13.4px 21px;
  border-bottom: 1px solid rgba(240, 240, 255, 0.1);
}

.sidebar_logo {
  width: -webkit-fill-available;
  max-width: max-content;
  max-height: 72px;
}

@keyframes sidebarActiveAnimation {
  from {
    width: 80px;
  }
  to {
    width: 240px;
  }
}

@keyframes sidebarActiveAnimation2 {
  from {
    width: 240px;
  }
  to {
    width: 80px;
  }
}

.sidebarItems {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.sidebarItems > li {
  margin: 0px;
  padding: 0px 0px;
  border-bottom: 1px solid rgba(240, 240, 255, 0.2);
}

.sidebarOneItemIconContainer {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebarOneItem_white {
  color: white;
}

.sidebarOneItemName {
  animation-name: sidebarOneItemNameAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

.sidebarOneItemName:hover {
  animation-name: sidebarOneItemNameHoverAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes sidebarOneItemNameAnimation {
  from {
    margin-left: 10px;
  }
  to {
    margin-left: 0px;
  }
}

@keyframes sidebarOneItemNameHoverAnimation {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: 10px;
  }
}

.sidebarOneItemIconContainer_active {
  /* background-color: #2a76f4; */
  background-color: #6259ca;
}
.sidebarOneItem_active_name {
  color: #6259ca;
}

.sidebarOneItemIcon {
  /* margin-right: 20px; */
}

.sidebarOneItemRow {
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 45px;

  position: relative;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 20px;
  cursor: pointer;
}

.sidebarOneItemRow_Active {
  background-color: #eaedf7;
  position: relative;
  margin: 10px 0px;
  margin-left: 5px;
  padding-left: 5px;
  border-radius: 30px 0px 0px 30px;
  cursor: pointer;
}
.sidebarOneItemRow_Active::before {
  border-right: 20px solid #2a76f4;
  content: '';
  position: absolute;
  top: -30px;
  z-index: 9;
  right: 0px;
  width: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 0px 48px 0px;
}

.sidebarOneItemRow_Active::after {
  border-right: 20px solid #2a76f4;
  content: '';
  position: absolute;
  top: 44.5px;
  z-index: 0;
  right: 0px;
  bottom: 0;
  height: 30px;
  width: 0px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 48px 0px 0px;
}

.sidebarOneItemRow_Active .shape1 {
  background: #eaedf7;
  position: absolute;
  top: -30px;
  width: 20px;
  height: 60px;
  right: 0px;
}

.sidebarOneItemRow_Active .shape2 {
  background: #eaedf7;
  position: absolute;
  top: 35px;
  width: 20px;
  height: 30px;
  right: 0px;
}

.sidebarOneItemRowActive {
  z-index: 100;
  height: auto;
  list-style-type: none;
  padding: 10px;
  cursor: pointer;
}

.sidebarOneItemRowActive > li {
  padding: 10px;
  padding-left: 10px;
  display: flex;
}

.sidebarOneItemRowActive > li > img {
  width: 18px;
  height: 18px;
  margin-right: 17px;
}

.sidebarOneItemRowInactive {
  height: 0px;
  overflow: hidden;
  animation-name: menuItemCloseAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

@keyframes menuItemOpenAnimation {
  from {
    height: 0px;
  }
  to {
    height: max-content;
  }
}

@keyframes menuItemCloseAnimation {
  from {
    height: 35px;
  }
  to {
    visibility: hidden;
    opacity: 0;
    z-index: -100;
    overflow: hidden;
  }
}

.sidebarOneItemRowExpandActive {
  margin-left: auto;
  display: block;
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  color: #2a76f4;
}

.sidebarOneItemRowExpandInactive {
  margin-left: auto;
  display: block;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.submenuIcon {
  /* font-family: 'typicons'; */
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  top: 8px;
  font-size: 12px;
  opacity: 0.4;
  margin-right: 22px;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1100px) {
  .sidebar {
    position: absolute;
    z-index: 90;
  }
  .sidebarActive {
    position: absolute;
    z-index: 90;
  }
}
@media (max-width: 700px) {
  .sidebar {
    display: none;
  }
}
