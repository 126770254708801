/* modal css */

.modalMain {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 100vh;
  overflow-y: scroll;
}
.modal_container {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 5px;
  position: relative;
}

.modal_container_big {
  max-width: 700px;
  position: absolute;
  top: 0px;
  height: max-content;
}

.modal_container > div {
  padding: 20px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a76f4;
  color: white;
}
.modal_header_close_button {
  cursor: pointer;
}
.modal_body p {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.modal_body input {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e6f1;
  margin-bottom: 10px;
}

.single_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.single_row > div {
  width: 100%;
}

@media (max-width: 500px) {
  .single_row {
    flex-direction: column;
  }
}

.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e1e6f1;
}

.modal_footer .btn_groups {
  display: flex;
  gap: 10px;
}

.modal_footer .btn_groups button {
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn_cancel {
  background-color: #fd6074;
}
.btn_cancel:hover {
  background-color: #e91e63;
}

.btn_request {
  background-color: #2a76f4;
}

.btn_request_disabled {
  background-color: #a8afc7;
  cursor: not-allowed !important;
}

.btn_request:hover {
  background-color: #0d44c5;
}

.modal_body .error_message {
  text-align: center;
  color: #fd6074;
  font-size: 14px;
  margin-top: -5px;
}

.modal_body textarea {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e1e6f1;
  margin-bottom: 10px;
  resize: none;
  height: 100px;
}
