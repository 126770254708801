.individual_chat_you {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
}

.individual_chat_you_logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.individual_chat_sender_logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.individual_chat_you_title {
  margin: 0px;
  margin-bottom: 5px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  color: #000000;
}

.individual_chat_sender_title {
  margin: 0px;
  margin-bottom: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  color: #000000;
}

.individual_chat_you_internal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}

.individual_chat_sender_internal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.individual_chat_you_msg {
  background: #000000;
  border: 1px solid #010101;
  border-radius: 10px;
  padding: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  color: #ffffff;
  white-space: pre-wrap;
}

.individual_chat_sender_msg {
  background: #ffffff;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  color: #000000;
  white-space: pre-wrap;
}

.individual_chat_sender {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.common_chat {
  background: rgba(29, 109, 229, 0.1);
  border-radius: 10px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common_chat img {
  margin-right: 12px;
}
