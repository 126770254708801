.createCampaign {
  /* padding: 30px; */
  height: 100%;
  position: relative;
  overflow-y: scroll;
}
.createCampaign_extraPadding {
  padding-top: 20px;
  margin: 0px 20px;
  position: relative;
  padding-bottom: 20px;
  min-height: calc(100% - 110px);
}

.createCampaign_allSteps {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  position: relative;
  background-color: white;
  padding-top: 50px;
  border-radius: 5px;
}

.createCampaign_form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.createCampaign_oneForm {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

.createCampaign_allSteps > div {
  flex: 1;
  /* min-width: 200px; */
}
.createCampaign_oneStepContainer_active,
.createCampaign_oneStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createCampaign_oneStepContainer_line {
  width: 100%;
  height: 2px;
}

.createCampaign_oneStepContainer_circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -23px;
}

.createCampaign_oneStepContainer_active
  .createCampaign_oneStepContainer_circle {
  background-color: #673ab7;
}

.createCampaign_oneStepContainer .createCampaign_oneStepContainer_circle {
  background-color: #787878;
}

.createCampaign_oneStepContainer_active .createCampaign_oneStepContainer_line {
  background-color: #673ab7;
}

.createCampaign_oneStepContainer .createCampaign_oneStepContainer_line {
  background-color: #afadad;
}

.createCampaign_oneStepContainer_stepName {
  margin: 0px;
  margin-top: -20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
}

.createCampaign_oneStepContainer_active
  .createCampaign_oneStepContainer_stepName {
  color: #673ab7;
}

.createCampaign_oneStepContainer .createCampaign_oneStepContainer_stepName {
  color: #787878;
}

.createCampaign_footer {
  display: flex;
  background-color: white;
  position: sticky;
  bottom: 0px;
  height: 70px;
  width: 100%;
  justify-content: end;
  align-items: center;
  /* padding-right: 100px; */
}

.createCampaign_footer_buttons {
  display: flex;
  gap: 10px;
  margin-right: 20px;
}

.createCampaign_footer_buttons button {
  width: 100px;
  height: 40px;
  background: #673ab7;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  bottom: 10px;
}

.createCampaign_inputs_row {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
  /* justify-content: center; */
}

@media (max-width: 700px) {
  .createCampaign_inputs_row {
    flex-direction: column;
  }
}

.createCampaign_form_inputBox {
  /* display: flex; */
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 100%;
}

.createCampaign_form_inputBox > div {
  display: flex;
  align-items: center;
}

.createCampaign_form_inputBox > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #f6f6ff;
  border: 1px solid #e8e8f7;
}

.createCampaign_form_inputBox > div > input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #f6f6ff;
  outline: none;
  border: none;
  width: 100%;

  padding: 0 10px;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  line-height: 44px;
}

.createCampaign_form_inputBox textarea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #f6f6ff;
  outline: none;
  border: none;
  width: 100%;

  padding: 0 10px;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  line-height: 44px;
}
.postingInfo > div {
  margin-bottom: 20px;
}

.createCampaign_form_inputBox select {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #f6f6ff;
  outline: none;
  border: none;
  width: 100%;

  padding: 0 10px;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  line-height: 44px;
}

.multiSelect > div {
  flex: 1;
  height: 100% !important;
}

.multiSelect > div > div > div {
  /* width: 100% !important; */
}
.createCampaign_form_inputBox_specialIcon {
  height: 100px !important;
}

.createCampaign_form_inputBox span {
  margin-bottom: 5px;
  display: block;
  color: #797979;
  font-size: 16px;
}

.createCampaign_oneForm {
  flex: 1;
}

.createCampaign_paymentMode {
  width: 50%;
}

.createCampaign_paymentMode_question > div {
  margin-bottom: 10px;
}

.createCampaign_paymentMode_question_options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.createCampaign_paymentMode_question_options input {
  margin-right: 10px;
}

.compensate_values {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.compensate_value_product {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* .createCampaign_paymentMode_value > div {
  height: 100%;
} */

.createCampaign_allObjectives {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}

.createCampaign_oneObjective {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 170px;
  min-width: 320px;
  max-width: 400px;
  flex: 1;
  padding: 10px;
  background-color: white;
  border: 1px dashed #2a76f4;
  cursor: pointer;
}

.createCampaign_onePlatform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 120px;
  width: 150px;
  padding: 10px;
  background-color: white;
  border: 1px dashed #2a76f4;
  cursor: pointer;
}

.createCampaign_allObjectives img {
  height: 50px;
  width: 50px;
}

.createCampaign_oneObjective_button {
  width: 100%;
  height: 40px;
  background: #673ab7;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 0px 5px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createCampaign_oneObjective_button input {
  height: 20px;
}

@media (max-width: 700px) {
  .createCampaign_oneStepContainer_circle {
    width: 15px;
    height: 15px;
    padding: 5px;
    top: -15px;
  }
  .createCampaign_oneStepContainer_stepName {
    font-size: 10px;
    text-align: center;
    margin-top: -15px;
  }
}
