.dashboardMain {
  width: 100%;
  height: 100%;
}

.container_design {
  position: relative;
  border-right: 1px solid #2a76f424;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  box-shadow: 0 10px 30px 0 rgba(24, 28, 33, 0.05);
  width: 100%;
  height: 160px;
  background: white;
}

.background_overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* margin-bottom: -220px; */

  width: 100%;
  height: 100%;
  background: url('../../../assets/brand/Hexagon.svg');
  z-index: 0;
  opacity: 0.06;
}

.dashboardMain_firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.dashboardMain_firstRow > div:nth-child(1) {
  flex: 5;
}
.dashboardMain_firstRow > div:nth-child(2) {
  flex: 4;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted #2a76f485;
  padding: 8px;
  padding-left: 15px;
  padding-top: 15px;
  z-index: 1;
  position: relative;
}
.titleBox p {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: #5b6069;
}

.titleBox select {
  margin-left: 10px;
  /* border: none; */
  outline: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  max-width: 150px;
}

.contentBoxForNumbers {
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  z-index: 1;
  flex-wrap: wrap;
}

.cardWithNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
  min-width: 100px;
}

.cardWithNumber p {
  margin: 0px;
}
.cardWithNumber span {
  margin-top: 10px;
}

@media (max-width: 1100px) {
  .dashboardMain_firstRow {
    flex-direction: column;
    align-items: center;
  }
}

.dashboardMain_secondRow {
  padding: 0px 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 10px;
}
.dashboardMain_secondRow h3 {
  color: #1c273c;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 15px;
}
.dashboardMain_secondRow_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: fill-available;
}

.dashboardMain_secondRow_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  height: max-content;
  width: fill-available;
}
.dashboardMain_secondRow_right .details {
  display: flex;
  gap: 20px;
}

.dashboardMain_secondRow_right .details p {
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 15px;
}
.dashboardMain_secondRow_right .details img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.dashboardMain_secondRow_right .details h6 {
  margin: 0px;
  color: #2a76f4;
  font-size: 16px;
  font-weight: 600;
}

.runningCampaignsCard {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0px;
  border-top: 1px dotted #2a76f461;
}
.runningCampaignsCard .campaignsImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.runningCampaignsCard .campaignsImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.campaignDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.campaignDetails p,
h6 {
  margin: 5px 0px;
}
.runningCampaignsCard_right {
  text-align: end;
  min-width: 100px;
}

.runningCampaignsCard_left h6 {
  font-size: 14px;
  font-weight: 500;
  color: #1d212f;
}
.runningCampaignsCard_left p {
  font-size: 13px;
  font-weight: 500;
  color: #1d212f;
}
.runningCampaignsCard_right h6 {
  font-size: 15px;
  font-weight: 500;
  color: #1d212f;
}
.runningCampaignsCard_right p {
  color: #474747;
  font-size: 11px;
}

@media screen and (max-width: 1100px) {
  .dashboardMain_secondRow {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .dashboardMain_secondRow_left {
    flex-direction: column;
    align-items: center;
  }
  .dashboardMain_secondRow_right,
  .oneReferralCard {
    max-width: 100%;
  }
}

/* color mapping */
.campaign_status_active {
  color: #49a240 !important;
}
.campaign_status_pitched {
  color: #f16d75 !important;
}
.campaign_status_accepted {
  color: #8ba52f !important;
}

.view_all_button_container {
  background-color: white;
  color: #6259ca;
  /* border-color: #6259ca; */
  border: 1px solid #6259ca;
  /* border: none; */
  outline: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
